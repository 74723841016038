html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  scroll-behavior: smooth;
}
.hide-title .logo-title {
  opacity: 0;
  height: 0;
  overflow: hidden;
  transition: opacity 0.3s ease, height 0.3s ease;
}

.navbar {
  background-color: #fff5d7;
  padding: 10px;
  color: #f2f2f2;
  text-align: center;
  position: sticky; /* Make the navbar sticky */
  top: 0;
  z-index: 1000;
  transition: all 0.3s ease;
}


.navbar h1 {
  margin: 21px;
  color: black;
  transition: opacity 0.3s ease, height 0.3s ease;
  font-size: 55px;
  font-weight: 1000;
  font-family: serif;
}

.navbar h2 {
  margin: 21px;
  color: black;
  transition: opacity 0.3s ease, height 0.3s ease;
  font-size: 36px;
  font-weight: 800;
  font-family: serif;
}

.navbar nav {
  display: flex;
  gap: 15px;
  justify-content: center;
  margin-top: 10px;
}

.navbar nav a {
  margin: 0 15px;
  color: black;
  text-decoration: none;
  font-weight: bold;
  position: relative;
  display: inline-block;
  padding: 10px 15px; 
  border-radius: 5px;
  transition: color 0.3s, background-color 0.3s, transform 0.3s;
  font-weight: 900;
  font-size: 20px;
}

.navbar nav a::after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 100%;
  height: 3px;
  background-color: #FFA500;
  transform: translateX(-50%) scaleX(0);
  transition: transform 0.3s ease-in-out;
}

.navbar nav a:hover {
  background-color: #FFA500; /* Black background on hover */
  color: black; /* Yellow text on hover */
  transform: scale(1.1); /* Slightly scale up the text */
}

.navbar nav a:hover::after {
  transform: translateX(-50%) scaleX(1);
}

/* Dropdown Menu Styles */
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%); /* Center align dropdown */
  background-color: #fff5d7;
  min-width: 150px; /* Increased width for better content layout */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  z-index: 1;
  border-radius: 5px;
  overflow: hidden;
  font-size: small;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown-content a {
  padding: 12px 20px; /* Consistent padding */
  text-decoration: none;
  display: block;
  transition: background-color 0.3s;
  border-bottom: 1px solid #eee; /* Subtle bottom border for separation */
}

.dropdown-content a:last-child {
  border-bottom: none; /* Remove border for last item */
}

.dropdown-content a:hover {
  background-color: #c084fc; /* Purple background on hover */
  color: #ffffff; /* White text on hover */
}

.services-nav-item {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

/* Down arrow next to "Services" */
.services-nav-item::after {
  content: '\25BC'; /* Unicode for down arrow */
  font-size: 12px;
  margin-left: 5px;
}

/* Mobile Responsive Adjustments */
@media (max-width: 768px) {
  .navbar h1 {
    font-size: 1.8em; /* Adjust the size as needed for a smaller heading */
    margin: 10px; /* Adjust margin to reduce spacing around heading */
  }
  .navbar h2{
    font-size: 1.1em;
    margin: 10px;
  }

  .navbar {
    padding: 5px; /* Reduce padding for more space */
  }

  .navbar nav {
    flex-direction: row; /* Keep links in a single row */
    justify-content: center; /* Center align links */
    gap: 5px; /* Reduced gap for smaller screens */
    flex-wrap: nowrap; /* Prevent wrapping to keep items in one line */
  }

  .navbar nav a {
    margin: 0; /* Smaller margin for compact fit */
    padding: 5px 4px; /* Reduced padding for more compact size */
    font-size: 10px; /* Smaller font size for mobile */
  }
}
