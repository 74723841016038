/* SevaDetails.css */

.seva-details-container {
    background-color: #fff7e6; /* Light, warm color to give a traditional feel */
    padding: 20px;
    border: 2px solid #8b4513; /* Rich brown color symbolizing earth and tradition */
    border-radius: 10px;
    max-width: 650px;
    margin: 20px auto;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .section-title {
    color: #4b0082; /* Indigo color for a spiritual vibe */
    font-size: 24px;
    margin-bottom: 15px;
    font-weight: bold;
  }
  
  .subsection-title {
    color: #d2691e; /* Earthy brown color */
    font-size: 20px;
    margin: 10px 0;
    border-bottom: 2px solid #8b4513;
    padding-bottom: 5px;
  }
  
  .seva-list {
    list-style: none;
    padding: 0;
  }
  
  .seva-item {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    background-color: #f9f2d1;
    margin: 5px 0;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .seva-item:hover {
    background-color: #f0e68c; /* Light gold color for hover effect */
  }
  
  .seva-title {
    color: #8b0000; /* Deep red color to represent devotion */
    font-weight: 500;
    font-size: 18px;
  }
  
  .seva-price {
    color: #006400; /* Dark green color indicating prosperity */
    font-weight: bold;
    font-size: 18px;
  }

/* Mobile View Styles */
@media (max-width: 768px) {
  .seva-details-container {
      padding: 15px; /* Reduced padding for smaller screens */
      max-width: 100%; /* Full width for smaller screens */
      margin: 10px; /* Reduced margin */
  }

  .section-title {
      font-size: 20px; /* Smaller font size */
      margin-bottom: 10px; /* Reduced margin */
  }

  .subsection-title {
      font-size: 18px; /* Smaller font size */
      margin: 8px 0; /* Reduced margin */
  }

  .seva-list {
      padding: 0; /* Remove padding for mobile */
  }

  .seva-item {
      display: flex;
      justify-content: space-between; /* Keep title and price on opposite sides */
      padding: 8px; /* Reduced padding */
      background-color: #f9f2d1;
      margin: 5px 0;
      border-radius: 5px;
      transition: background-color 0.3s;
      font-size: 14px; /* Smaller font size for mobile */
  }

  .seva-item:hover {
      background-color: #f0e68c; /* Light gold color for hover effect */
  }

  .seva-title,
  .seva-price {
      font-size: 14px; /* Smaller font size for both title and price */
  }

  .seva-title {
      color: #8b0000; /* Deep red color to represent devotion */
      font-weight: 500;
  }

  .seva-price {
      color: #006400; /* Dark green color indicating prosperity */
      font-weight: bold;
  }
}

  