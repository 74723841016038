.footer {
  background-color: #fff5d7;
  color: #000000;
  padding: 20px 10px; 
  text-align: center;
  position: relative;
  overflow: hidden;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 20px; /* Default margin */
}

.contact-info, .footer-links {
  flex: 1;
  min-width: 250px;
  padding: 10px; /* Default padding */
}

.contact-info h2, .footer-links h2 {
  font-size: 2em; /* Default font size */
  margin-bottom: 15px; /* Default margin */
  font-family: serif;
}

.contact-info p, .footer-links ul {
  font-size: 1em; /* Default font size */
  line-height: 1.6; /* Default line height */
  font-family: serif;
}

.contact-info p a, .footer-links a {
  color: #000000;
  text-decoration: none;
  font-weight: bold;
}

.contact-info p a:hover, .footer-links a:hover {
  text-decoration: underline;
  color: #000000;
}

.footer-links ul {
  list-style: none;
  padding: 0;
}

.footer-links ul li {
  margin: 10px 0; /* Default margin for list items */
}

.footer-bottom {
  background: rgba(0, 0, 0, 0.3);
  padding: 15px 0; 
  color: #000000;
  font-size: 0.9em; /* Default font size */
  border-top: 1px solid #ddd;
}

.footer-bottom p {
  margin: 0;
}

/* Mobile Responsive Adjustments */
@media (max-width: 768px) {
  .footer {
    padding: 15px 5px; /* Reduced padding for smaller screens */
    font-size: 0.8em; 
  }

  .footer-content {
    flex-direction: column;
    align-items: center;
    margin-bottom: 15px; /* Reduced margin for mobile */
  }

  .contact-info, .footer-links {
    text-align: center;
    margin-bottom: 10px; /* Adjusted margin for mobile */
  }

  .contact-info h2, .footer-links h2 {
    font-size: 1.5em; /* Reduced font size for mobile */
    margin-bottom: 10px; /* Reduced margin for mobile */
  }

  .contact-info p, .footer-links ul {
    font-size: 0.8em; /* Reduced font size for mobile */
    line-height: 1.4; /* Reduced line height for mobile */
  }

  .footer-links ul {
    padding: 0;
  }

  .footer-links ul li {
    margin: 5px 0; /* Reduced margin for list items on mobile */
  }

  .footer-bottom {
    font-size: 0.7em; 
    padding: 8px 0; 
  }
}
