/* Container */
.gallery-container {
  background: linear-gradient(to bottom, #f5f5f5, #e0e0e0); /* Soft, serene gradient for a devotional feel */
  padding: 80px 40px;
  text-align: center;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5);
  max-width: 1400px;
  margin: 0 auto;
  position: relative;
}

/* Overlay for Background */
.gallery-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.3); /* Light overlay for text readability */
  border-radius: 15px;
  z-index: 1;
}

/* Title */
.gallery-title {
  color: #333333;
  font-size: 35px;
  margin-bottom: 60px;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  position: relative;
  z-index: 2;
  transition: color 0.3s ease-in-out;
  font-family: sans-serif;
}

.gallery-title:hover {
  color: #d4af37; /* Gold color for a royal feel */
}

/* Grid Layout */
.gallery-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;
  align-items: center;
  justify-items: center;
  position: relative;
  z-index: 2;
}

/* Gallery Item */
.gallery-item {
  position: relative;
  overflow: hidden;
  border-radius: 15px;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.4);
  transition: transform 0.4s ease, box-shadow 0.4s ease;
  height: 400px; 
  width: 100%;
  max-width: 300px;
}

/* Hover Effect for Gallery Item */
.gallery-item:hover {
  transform: scale(1.03); /* Slight zoom effect */
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.6);
}

/* Image Styling */
.gallery-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.4s ease-in-out, filter 0.4s ease-in-out;
  filter: grayscale(20%); /* Slight grayscale for a more elegant look */
}

/* Hover Effect for Image */
.gallery-item:hover .gallery-image {
  transform: scale(1.1);
  filter: grayscale(0); /* Return to full color on hover */
}

/* Description */
.gallery-description {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5); /* Slightly lighter background */
  color: #ffffff;
  padding: 20px;
  font-size: 14px;
  text-align: center;
  opacity: 1; /* Always visible */
  transform: translateY(0); /* Always visible */
  border-radius: 0 0 15px 15px;
}
/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

/* Modal */
.modal {
  position: relative;
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  max-width: 90%;
  max-height: 80%;
  overflow: auto;
}

.modal-image {
  width: 100%;
  height: auto;
}

.modal-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #000;
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
}
/* Previous and Next Arrows for Modal */
.modal-prev-arrow, .modal-next-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.4); /* Semi-transparent background */
  border: none;
  color: white;
  font-size: 1.8rem;
  padding: 12px;
  cursor: pointer;
  z-index: 1001;
  border-radius: 50%;
  transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease, border 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.3); /* Soft shadow */
  line-height: 0; /* Ensure text does not affect layout */
  animation: arrow-fade-in 0.5s ease-out; /* Apply the fade-in animation */
}

/* Left Arrow */
.modal-prev-arrow {
  left: 20px; /* Positioned to the left */
}

/* Right Arrow */
.modal-next-arrow {
  right: 20px; /* Positioned to the right */
}

/* Hover Effects for Fresh Arrows */
.modal-prev-arrow:hover, .modal-next-arrow:hover {
  background-color: rgba(0, 0, 0, 0.7); /* Darker background on hover */
  transform: scale(1.1); /* Slight scaling effect */
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.5); /* Enhanced shadow for depth */
  border: 2px solid #fff; /* Adds a border for emphasis */
}

/* Add arrow icons */
.modal-prev-arrow::before, .modal-next-arrow::before {
  content: '';
  border: solid white;
  border-width: 0 3px 3px 0;
  padding: 10px;
  display: inline-block;
  transition: transform 0.3s ease, color 0.3s ease;
}

/* Left Arrow Icon */
.modal-prev-arrow::before {
  transform: rotate(135deg); /* Left arrow direction */
}

/* Right Arrow Icon */
.modal-next-arrow::before {
  transform: rotate(-45deg); /* Right arrow direction */
}

/* Hover Animation for Arrow Icons */
.modal-prev-arrow:hover::before, .modal-next-arrow:hover::before {
  color: #ffd700; /* Change arrow color on hover */
  transform: rotate(135deg) scale(1.1); /* Scales the left arrow */
}

.modal-next-arrow:hover::before {
  color: #ffd700; /* Change arrow color on hover */
  transform: rotate(-45deg) scale(1.1); /* Scales the right arrow */
}

@keyframes arrow-fade-in {
  0% {
    opacity: 0;
    transform: translateY(-50%) scale(0.8);
  }
  100% {
    opacity: 1;
    transform: translateY(-50%) scale(1);
  }
}
/* Responsive Design */
@media (max-width: 768px) {
  .gallery-title {
    font-size: 20px;
    margin-bottom: 40px;
  }

  .gallery-grid {
    grid-template-columns: 1fr;
  }

  .gallery-description {
    font-size: 16px;
    padding: 15px;
  }

  .gallery-item {
    height: auto; /* Allow images to resize appropriately on smaller screens */
  }
  /* Responsive for small devices */

  .modal-prev-arrow, .modal-next-arrow {
    width: 40px;
    height: 40px;
    font-size: 1.5rem;
  }
}

