/* General App Styles */
html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: #f2f2f2;
  font-family: serif;
}

.App {
  display: flex;
  flex-direction: column;
  font-family: serif;
}


.main-content-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px; 
}

.fb-page-container {
  position: absolute;
  right: 0;
  top: 184px;
  width: 400px;
  height: 600px;
  
}

.title-image {
  width: 1120px; 
  height: 565px; 
}

.content-container {
  padding: 5%;
  margin-top: 0px; 
}

.center-container {
  text-align: center;
  margin-top: 0px; 
}

p {
  margin: 10px;
  padding: 0;
  line-height: 1.6;
  font-size: 16px;
  margin-bottom: 20px;
}

.temple-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 20px 0;
}

.temple-heading {
  margin: 10px;
}

.temple-image {
  width: 40%;
  height: auto;
}

/* App.css */
.events-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: #f4f4f4;
  padding: 20px;
}

.events-section {
  text-align: center;
  background: #fff; 
  border-radius: 15px; 
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5);
  padding: 20px;
  max-width: 900px; 
  width: 100%; 
  box-sizing: border-box; 
}

/* Title styling */
.events-title {
  color: #333; /* Dark color for the title */
  font-size: 2rem; /* Larger font size */
  margin-bottom: 20px; /* Space below the title */
}

/* Container for responsive iframe */
.events-iframe-container {
  position: relative; /* For responsive iframe */
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
  height: 0;
  overflow: hidden;
  border-radius: 8px; /* Rounded corners for iframe */
  max-width: 100%; /* Ensure container does not exceed its parent */
  margin: 0 auto; /* Center the container */
}

/* iframe styling */
.events-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none; /* No border for iframe */
}

.timings-section {
  background-color: rgba(255, 250, 240, 0.9); 
  padding: 10px; 
  border-radius: 15px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); 
  margin: 30px auto;
  max-width: 45%; 
}

.timings-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap; 
  margin: 0 auto;
  padding: 5px; 
}

.timing-card {
  background-color: #fff;
  border-radius: 8px; 
  padding: 10px; 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
  transition: transform 0.3s ease;
  max-width: 220px; 
  margin: 8px; 
}

.timing-card:hover {
  transform: translateY(-3px); 
}

.timing-icon {
  font-size: 2rem; 
  color: #FF6347;
  margin-bottom: 8px; /* Slightly reduced margin */
}

.day {
  font-size: 1.3rem; /* Slightly reduced font size */
  color: #B45F06;
}

.timings {
  font-size: 1rem; /* Slightly smaller text */
  color: #333;
  margin: 4px 0; /* Reduced margin */
}
.notification-container {
  max-width: 600px;
  margin: 20px auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5);
}

.notification-title {
  font-size: 30px;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}

.notification-list {
  list-style-type: none; /* Changed to remove bullets */
  padding-left: 0; /* Aligns with container */
}

.notification-item {
  background-color: #fff;
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-size: larger;
  display: flex;
  flex-direction: column; /* Aligns the message and date vertically */
  justify-content: space-between;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.notification-item:hover {
  background-color: #e0f7fa; /* Light cyan background */
  box-shadow: 0 4px 10px rgba(0, 128, 128, 0.2); /* Soft shadow effect */
  transform: translateY(-2px); /* Slight upward movement */
}

.notification-delete-button {
  background-color: #e57373;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 14px;
  align-self: flex-end; /* Aligns button to the right */
  margin-top: 10px; /* Adds space between content and button */
}

.notification-delete-button:hover {
  background-color: #c62828;
}

.notification-item span {
  flex-grow: 1; /* Ensures the text takes up available space */
  padding-right: 10px; /* Adds space between the text and the label */
}

.notification-new-label {
  position: relative;
  margin-left: 10px; /* Space between the text and label */
  background-color: #ffeb3b;
  color: #000;
  padding: 3px 10px;
  border-radius: 12px;
  font-weight: bold;
  box-shadow: 0 0 10px rgba(255, 165, 0, 0.8);
  text-shadow: 0 0 5px rgba(255, 105, 0, 0.9);
}

.notification-timestamp {
  font-size: 12px;
  color: #555;
  margin-top: 5px; 
  align-self: flex-start; 
}

.notification-textarea {
  width: 100%;
  height: 100px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
  resize: none;
  font-size: 16px;
  box-sizing: border-box;
}

.notification-add-button {
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
}

.notification-add-button:hover {
  background-color: #388e3c;
}

.notification-alert {
  color: #d32f2f;
  font-weight: bold;
}

.login-register-container {
  max-width: 400px;
  margin: 0 auto;
  margin-top: 40px;
  margin-bottom: 40px;
  padding: 20px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}

.form-container {
  display: flex;
  flex-direction: column;
}

input {
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
}

.auth-button {
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  font-size: 16px;
}

.auth-button:hover {
  background-color: #388e3c;
}

.toggle-link {
  color: #007bff;
  cursor: pointer;
  text-align: center;
  margin-top: 10px;
  font-size: 14px;
}

.toggle-link:hover {
  text-decoration: underline;
}

.admin-login-container-right {
  display: flex;
  justify-content: flex-end; /* Align the button to the right */
  margin: 20px 20px 0 0; /* Adjust the spacing around the button */
}

.admin-login-button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
}

.admin-login-button:hover {
  background-color: #388e3c;
}

@media (max-width: 768px) {
  /* Main Content Container */
  .main-content-container {
    padding: 8px; /* Further reduced padding */
  }

  /* Facebook Page Container */
  .fb-page-container {
    position: static; /* Change to relative positioning */
    width: 100%; /* Full width for mobile */
    height: auto; /* Auto height */
    top: 0; /* No top offset */
    margin-left: 0; 
    padding:auto;
  }

  /* Title Image */
  .title-image {
    width: 100%; /* Full width */
    height: auto; /* Adjust height automatically */
    margin-left: 0; /* No left margin */
    position: relative;
    left: 0;
    transform: none;
  }

  /* Content Container */
  .content-container {
    padding: 8px; /* Reduced padding */
    margin-top: 10px; /* Reduced margin */
  }

  /* Center Container */
  .center-container {
    font-size: 0.7rem; /* Smaller font size for mobile */
    margin-top: 10px; /* Reduced margin */
  }

  /* Paragraphs */
  p {
    margin: 6px 0; 
    line-height: 1.3; 
    font-size: 14px; 
  }

  .temple-image-container {
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 10px 0;
  }

  /* Temple Image */
  .temple-image {
    width: 80%; /* Adjust width for mobile */
  }

  .events-container {
    min-height: 60vh;
    padding: 20px;
  }

  .events-iframe-container {
    padding-top: 80%; /* Increase the aspect ratio for more height */
    height: 200px; 
    width: 100%;
  }

  .events-iframe {
    height: 100%; /* Ensure the iframe fills the container height */
  }

  .events-title {
    font-size: 1.4rem; /* Optional: Increase title font size for better visibility */
    margin-bottom: 20px; /* Optional: Add more space below the title */
  }

  .events-section {
    padding: 20px; /* Optional: Add more padding to the section */
  }

  .timings-section {
    padding: 10px; /* Less padding on mobile */
    max-width: 90%; /* Full width on mobile */
  }

  .timing-card {
    max-width: 100%; /* Full width for mobile */
  }

  .timing-icon {
    font-size: 1.8rem; /* Further reduced size */
  }

  .day {
    font-size: 1.1rem; 
  }

  .timings {
    font-size: 0.9rem; 
  }

  .notification-container {
    width: 88%;
  }

  .login-register-container {
    margin-top: 70px;
    margin-bottom: 70px;
    
  }

  .notification-title {
    font-size: 24px;
  }

  .notification-item {
    font-size: medium;

  }

}

